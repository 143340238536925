.Header{
    background-color: #231046;
    width: 100vw;
    height: 15vh;
    box-sizing: border-box;
    text-align: center;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BannerLogo{
    max-width: 100vw;
    max-height: 15vh;
}